import ButtonActions from '@/components/buttonActions';
import { EXPORT_BENEFICIARIES_TYPE } from '@/modules/beneficiary/entities';
import { USER_TYPE } from '@/modules/user/entities/user.const';
import { UserType } from '@/modules/user/entities/user';
import ICONS from '@/shareds/constants/icons';
import React from 'react';
import { Button, Icon } from 'vkit/lib/components';
import { Tooltip } from 'vkit/lib/context';

interface ExportBeneficiariesButtonProps {
  handleExportBeneficiaries: (exportType: EXPORT_BENEFICIARIES_TYPE) => void;
  userType: UserType;
}

const ExportBeneficiariesButton: React.FC<ExportBeneficiariesButtonProps> = (
  {
    handleExportBeneficiaries,
    userType,
  },
) => {
  return (
    <Tooltip title={'Exportação feita de acordo com o filtro da lista'}>
      {
        userType === USER_TYPE.COMPANY ? (
          <Button
            testId='export_beneficiary'
            onClick={() => handleExportBeneficiaries(EXPORT_BENEFICIARIES_TYPE.BASIC)}
            solo={true}
            icon={ICONS.DOWNLOAD}
            invertColor={true}
            iconColor={'light'}
            size='xLarger'
          />
        ) : (
          <ButtonActions
            color='transparent'
            actionOptions={[
              {
                label: 'Exportação básica',
                onClick: () => handleExportBeneficiaries(EXPORT_BENEFICIARIES_TYPE.BASIC)
              },
              {
                label: 'Exportação completa',
                onClick: () => handleExportBeneficiaries(EXPORT_BENEFICIARIES_TYPE.CADASTRAL_POSITION)
              }
            ]}
            size='xLarger'
            padding='0px'
            hideArrow
          >
            <Icon name={ICONS.DOWNLOAD} size={32} color='colorDefault' />
          </ButtonActions>
        )
      }
    </Tooltip>
  );
};

export default ExportBeneficiariesButton;
