import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import {
  Beneficiary,
  BeneficiaryNormalized,
  BeneficiaryResponse,
  BeneficiarySearchParams,
  EXPORT_BENEFICIARIES_TYPE,
  ExportBeneficiarySearchParams,
} from '../entities';
import { normalizeBeneficiary, normalizeCounterFilters, normalizeFilters } from '../utils';

class BeneficiaryService extends HttpClientAdapter {
  constructor() {
    super('BENEFICIARY');
  }

  getCounter<T = any>(companyId: string, filters: BeneficiarySearchParams = {}) {
    const url = `/companies/${companyId}/beneficiaries`;
    return this.get<T>(url, normalizeCounterFilters(filters));
  }

  getBeneficiaries<T = any>(filters: BeneficiarySearchParams = {}) {
    const url = '/beneficiaries';
    return this.get<T>(url, normalizeFilters(filters), {
      transformResponse: [this.normalizeCollection],
    });
  }

  getBeneficiary<Beneficiary>(companyId: string, beneficiaryId: string) {
    const url = `/companies/${companyId}/beneficiaries/${beneficiaryId}`;
    return this.get<Beneficiary>(
      url,
      {},
      {
        transformResponse: [this.normalize],
      },
    );
  }

  getExportedBeneficiaries<T = any>(
    filters: ExportBeneficiarySearchParams = { exportType: EXPORT_BENEFICIARIES_TYPE.BASIC },
  ) {
    const normilizedFilters = normalizeFilters(filters);

    return this.get<T>('/beneficiaries/export-beneficiaries', {
      ...normilizedFilters,
      exportType: filters?.exportType,
    }, {
      responseType: 'blob',
    });
  }

  private normalize(data: Beneficiary): BeneficiaryNormalized {
    return normalizeBeneficiary(data);
  }

  private normalizeCollection(response: BeneficiaryResponse) {
    const { page, data = [], totalPages, total, pageSize } = response;
    const normalized = data.map((item) => normalizeBeneficiary(item));
    return {
      page,
      totalPages,
      total,
      data: normalized,
      pageSize,
    };
  }
}

export default BeneficiaryService;
