import { Negotiation } from '@/modules/negotiation';
import { BasePagination, BaseSearchParams } from '@/shared/entities';
import { BENEFICIARY_STATUS, BENEFICIARY_STATUS_ALIAS_TRANSLATED } from '../constants';
import { BeneficiaryOwnershipTranslated } from '../types';
import { BeneficiaryStatusAlias } from '../types/beneficiaryStatus';
import { BankAccount } from './bankAccount';
import { HealthCard } from './healthCard';
import { Life, LifeNormalized } from './life';

export interface BeneficiaryHolder {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  deletionReason: string | null;
  beneficiaryId: string;
  holderId: string;
}

export interface Beneficiary {
  bankAccount: BankAccount | null;
  beneficiaryHolder: BeneficiaryHolder;
  companyId: string;
  createdAt: string;
  deletedAt: string | null;
  deletionReason: string | null;
  healthCard: HealthCard;
  id: string;
  life: Life;
  lifeId: string;
  negotiationId: string;
  status: BENEFICIARY_STATUS;
  updatedAt: string | null;
  validFrom: string;
  validUntil: string | null;
}

export interface BeneficiaryNormalized extends Omit<Beneficiary, 'healthCard' | 'life' | 'status'> {
  ownership: BeneficiaryOwnershipTranslated;
  isHolder: boolean;
  healthCard: HealthCard;
  healthCardNumber: string;
  status: BENEFICIARY_STATUS_ALIAS_TRANSLATED;
  negotiation?: Negotiation;
  negotiationId: string;
  life: Partial<LifeNormalized>;
}

export interface BeneficiarySearchParams extends BaseSearchParams {
  id?: string;
  name?: string;
  cpf?: string;
  status?: BeneficiaryStatusAlias;
  type?: string;
  healthCard?: string;
  benefit?: string;
  negotiationId?: string[];
  benefitId?: string;
  companyId?: string;
  'beneficiaryHolder.holderId'?: string;
  isHolder?: boolean;
  sort?: string;
}

export interface BeneficiaryCounters {
  beneficiaries: number;
  dependents: number;
  holders: number;
  lives: number;
}

export interface BeneficiaryResponse extends BasePagination {
  data: Beneficiary[];
}

export interface BeneficiaryResponseNormalized extends BasePagination {
  data: BeneficiaryNormalized[];
}

export enum EXPORT_BENEFICIARIES_TYPE {
  BASIC = 'basic',
  CADASTRAL_POSITION = 'pc',
}

export interface ExportBeneficiarySearchParams extends BeneficiarySearchParams {
  exportType: EXPORT_BENEFICIARIES_TYPE;
}
