import DataGrid from '@/components/dataGrid/dataGrid';
import { COMPANY_PERMISSION } from '@/core/entities/interfaces/permissions';
import { Status } from '@/modules/beneficiary/components';
import ExportBeneficiariesButton from '@/modules/beneficiary/components/exportBeneficiariesButton/exportBeneficiariesButton';
import ExportBeneficiariesModal from '@/modules/beneficiary/components/exportBeneficiariesModal/exportBeneficiariesModal';
import {
  BENEFICIARY_EXTRA_FILTERS,
  BENEFICIARY_STATUS_ALIAS_TRANSLATED,
  OWNERSHIP_TRANSLATED,
} from '@/modules/beneficiary/entities/constants';
import {
  BeneficiaryNormalized,
  BeneficiaryResponseNormalized,
  BeneficiarySearchParams,
  EXPORT_BENEFICIARIES_TYPE,
  ExportBeneficiarySearchParams,
} from '@/modules/beneficiary/entities/interfaces';
import BeneficiaryService from '@/modules/beneficiary/services/beneficiaryService';
import Counter from '@/modules/beneficiary/views/counter/counter';
import { SelectNegotiationsByBenefit, useNegotiations } from '@/modules/negotiation';
import { useCurrentUser } from '@/modules/profile';
import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import { analytics } from '@/shared/telemetry/telemetry';
import { isEmpty, omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface BeneficiaryQueryParams extends BeneficiarySearchParams {
  showMoreFilters?: boolean;
}

const List: React.FC = () => {
  const { checkUserHasPermission, user } = useCurrentUser();
  const { negotiationsById } = useNegotiations();
  const [showExportBeneficiaries, setShowExportBeneficiaries] = useState<boolean>(false);

  const [filters, setFilters] = useQueryStringState<BeneficiaryQueryParams>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
    status: 'ACTIVE',
    sort: 'life.name',
  });
  
  const [paramsToExport, setParamsToExport] = useState<ExportBeneficiarySearchParams>({
    exportType: EXPORT_BENEFICIARIES_TYPE.BASIC,
    status: 'ACTIVE',
    sort: 'life.name',
  })

  const canExportBeneficiary = useMemo(
    () => checkUserHasPermission(COMPANY_PERMISSION.BENEFICIARY_EXPORT),
    [checkUserHasPermission],
  );

  const handleExportBeneficiaries = (exportType: EXPORT_BENEFICIARIES_TYPE) => {
    analytics.track('[START] - Exportação de beneficiários');
    setParamsToExport({ ...omit(filters, ['page', 'pageSize']), exportType })
    setShowExportBeneficiaries(true);
  };

  const getBeneficiaries = useCallback(
    async (filters: BeneficiarySearchParams, companyId: string) => {
      const { data, ...pagination } =
        await new BeneficiaryService().getBeneficiaries<BeneficiaryResponseNormalized>({
          ...filters,
          companyId,
        });

      return {
        ...pagination,
        data: data.map((beneficiary) => {
          return {
            ...beneficiary,
            negotiation: negotiationsById[beneficiary.negotiationId],
          };
        }),
      };
    },
    [negotiationsById],
  );

  return (
    <>
      <Grid gap={8}>
        <Text color='colorDefault' title size='medium' value='Lista de beneficiários' />
        {canExportBeneficiary && (
          <ExportBeneficiariesButton
            handleExportBeneficiaries={handleExportBeneficiaries}
            userType={user.type}
          />
        )}
      </Grid>

      <ExportBeneficiariesModal
        isOpen={showExportBeneficiaries}
        handleClose={setShowExportBeneficiaries}
        filters={paramsToExport}
      />

      <Counter filters={filters} />

      <DataGrid
        key={String(isEmpty(negotiationsById))}
        filterStateController={[filters, setFilters]}
        source={getBeneficiaries}
        filters={[
          {
            label: 'Nome',
            key: 'name',
          },
          {
            label: 'CPF',
            key: 'cpf',
          },
          {
            label: 'Status',
            key: 'status',
            type: 'select',
            data: Object.entries(BENEFICIARY_STATUS_ALIAS_TRANSLATED).map(([key, val]) => ({
              text: `${val}s`,
              value: key,
            })),
          },
          {
            label: 'Tipo',
            key: 'type',
            type: 'select',
            extra: true,
            data: Object.entries(OWNERSHIP_TRANSLATED).map(([key, val]) => ({
              text: val,
              value: key,
            })),
          },
          {
            label: 'Carteirinha',
            key: 'healthCard',
            extra: true,
          },
          {
            extra: true,
            render: ({ benefit }, onChange) => (
              <SelectNegotiationsByBenefit
                key={benefit || BENEFICIARY_EXTRA_FILTERS.BENEFIT}
                value={benefit}
                onChangeSelected={(benefit, negotiationId) => onChange({ benefit, negotiationId })}
              />
            ),
          },
        ]}
        columns={[
          {
            headerName: 'Nome',
            field: 'life.name',
            sup: {
              field: 'ownership',
              textParams: ({ isHolder }) => ({
                color: isHolder ? 'success' : 'colorDefault',
              }),
            },
          },
          {
            headerName: 'CPF',
            field: 'life.cpf',
          },
          {
            headerName: 'Carteirinha',
            field: 'healthCardNumber',
          },
          {
            headerName: 'Benefício',
            field: 'negotiation.product.benefit.name',
          },
          {
            headerName: 'Status',
            render: ({ status }: BeneficiaryNormalized) => <Status label={status} />,
          },
        ]}
        actionsByItem={[
          {
            redirectToDetail: ({ id }, companyId) =>
              `/companies/${companyId}/beneficiaries/${id}/details`,
          },
        ]}
      />
    </>
  );
};

export default List;
