import {
  ExportBeneficiarySearchParams,
  MAX_BENEFICIARIES_EXPORTED_FILENAME_LENGTH,
} from '@/modules/beneficiary/entities';
import { BeneficiaryService } from '@/modules/beneficiary/services';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

const beneficiaryService = new BeneficiaryService();

const useExportBeneficiriesModal = () => {
  const { company } = useCompany();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const resetAll = () => {
    setLoading(false);
    setSuccess(false);
    setError(false);
  };

  const downloadFile = useCallback(
    (response: Blob) => {
      const formatedDate = `-${format(new Date(), 'dd-MM-yyyy-HH-mm-ss')}`;
      const filenamePrefix = 'beneficiarios-';
      const fixedNameSize = filenamePrefix.length + formatedDate.length;
      const limitedCompanyName = company.name.slice(
        0,
        MAX_BENEFICIARIES_EXPORTED_FILENAME_LENGTH - fixedNameSize,
      );
      const filename = `${filenamePrefix}${limitedCompanyName}${formatedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response]));
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    [company.name],
  );

  const getExportedData = useCallback(
    async (filters: ExportBeneficiarySearchParams) => {
      if (!filters.companyId) {
        return;
      }
      setLoading(true);
      setSuccess(false);
      setError(false);

      try {
        const response = await beneficiaryService.getExportedBeneficiaries(filters);
        downloadFile(response);
        setSuccess(true);
      } catch (e) {
        setError(true);
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [downloadFile],
  );

  const disableCloseButton = useMemo(() => {
    return loading && (!error || !success);
  }, [error, loading, success]);

  return {
    loading,
    error,
    getExportedData,
    success,
    disableCloseButton,
    resetAll,
  };
};

export default useExportBeneficiriesModal;
